import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Vulva from "../../images/vulvas/vulva-1-labelled.png"

const Container = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
`
const Title = styled.h2`
  padding: 0 24px;
  text-align: center;
  color: ${props => props.theme.shade[10]};
`
const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-image: url(${Vulva});
  background-size: contain;
  background-position: center;
  margin: 0 auto;
`

export default function ImageHotspot({ title }) {
  return (
    <Container>
      <Title>{title}</Title>
      <ImageContainer />
    </Container>
  )
}

ImageHotspot.propTypes = {
  hotSpots: PropTypes.object,
  title: PropTypes.string,
}
