import React from "react"
import styled from "@emotion/styled"
import Layout from "../../components/global/layout"
import ButtonLink from "../../components/form/ButtonLink"
import ImageHotSpotResults from "../../components/block/ImageHotSpotResults"
import { urls } from "../../data/urls"

const Grid = styled.div`
  display: grid;
  padding: 0 24px;
  grid-template-rows: 1fr max-content;
  gap: 32px;
  height: 100%;
  width: 100%;
  padding: 0 24px 32px 24px;
  animation: fadeIn 0.2s linear;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export default function HotSpotPage(props) {
  return (
    <Layout title="Know your vulva">
      <Grid>
        <ImageHotSpotResults title="Complete!" />
        <ButtonLink
          backgroundColor={props => props.theme.rose}
          to={urls.KYV.RESULTS}
        >
          See how well you did!
        </ButtonLink>
      </Grid>
    </Layout>
  )
}
